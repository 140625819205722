export default function Badge({ count, className }){
  if (!count) return ''

  const renderText = () => {
    if (count <= 99) return count
    return '99+'
  }
  return <div className={ cn('bg-red-500 rounded-full text-white text-[0.6rem] px-[0.4rem] h-4 inline-flex items-center', className) }>
    { renderText() }
  </div>
}
